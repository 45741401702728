import BaseLink from 'gatsby-link';
import styled from 'styled-components';

import { TextInput as BaseTextInput } from 'components/UI/Input/TextInput';
import {
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.footer`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: ${Colors.gray[1]};
`;

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.tablet} {
		justify-content: space-between;
		max-width: ${TABLET_GRID_SIZE}rem;
		margin-top: 12rem;
		margin-bottom: 12rem;
	}

	@media ${MediaQueries.desktop} {
		flex-wrap: nowrap;
		max-width: ${DESKTOP_GRID_SIZE}rem;
		padding: 0 ${DESKTOP_GRID_COLUMN + DESKTOP_GRID_SPACING}rem;
		margin-top: 12rem;
		margin-bottom: 12rem;
	}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.phone} {
		margin-top: 6rem;
		align-items: center;
	}

	@media ${MediaQueries.tablet} {
		width: 18rem;
	}

	@media ${MediaQueries.desktop} {
		width: ${DESKTOP_GRID_COLUMN * 2 + DESKTOP_GRID_SPACING}rem;
	}
`;

export const UpdatesColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 8rem;

	@media ${MediaQueries.phone} {
		margin-top: 6rem;
		align-items: center;
	}

	@media ${MediaQueries.desktop} {
		width: ${DESKTOP_GRID_COLUMN * 4 + DESKTOP_GRID_SPACING * 3}rem;
		margin-top: 0;
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: relative;
`;

export const TextInput = styled(BaseTextInput)`
	display: flex;
	flex: 1;
	margin-right: 2rem;
`;

export const Title = styled.h4`
	font-size: 2.4rem;
	line-height: 2.9rem;
`;

export const Separator = styled.div`
	height: 0.1rem;
	width: ${DESKTOP_GRID_COLUMN}rem;
	background-color: ${Colors.yellow};
	margin-top: 1rem;
	margin-bottom: 4rem;
`;

export const Item = styled.p`
	border-bottom: 0.2rem transparent solid;
	cursor: pointer;
	font-weight: normal;
	margin-bottom: 1rem;
	transition: all 0.2s;
	width: fit-content;

	&:hover {
		color: ${Colors.green};
		border-color: ${Colors.green};
	}
`;

export const Link = styled(BaseLink)`
	cursor: default;
	border-bottom: 0.2rem transparent solid;
	font-weight: normal;
	margin-bottom: 1rem;
	transition: all 0.2s;
	width: fit-content;

	&.active-link {
		color: ${Colors.green};
	}

	:not(.active-link) {
		cursor: pointer;
	}

	&:hover {
		color: ${Colors.green};

		:not(.active-link) {
			border-color: ${Colors.green};
		}
	}
`;

export const Socials = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 4rem;

	@media ${MediaQueries.phone} {
		justify-content: center;
	}
`;

export const SocialIcon = styled.img`
	margin-right: 2rem;
	cursor: pointer;
`;

export const Copyright = styled.p`
	color: ${Colors.indigo};
	padding: 2.6rem 0;

	@media ${MediaQueries.phone} {
		padding: 2.6rem 5.5rem;
		text-align: center;
	}
`;

export const Success = styled.span`
	position: absolute;
	color: ${Colors.green};
	bottom: -2.5rem;
`;

export const Error = styled.span`
	position: absolute;
	color: ${Colors.danger};
	bottom: -2.5rem;
`;

export const MailChimpConsent = styled.div`
	display: flex;
	align-items: center;
`;

export const MailChimpLabel = styled.label`
	font-size: 1.4rem;
	line-height: 2.2rem;
	font-weight: 500;
	color: ${Colors.gray[7]};
	margin-bottom: 1rem;
	border-bottom: 0.2rem transparent solid;

	@media ${MediaQueries.phone} {
		margin-bottom: 2rem;
		text-align: center;
	}
`;

export const MailChimpLink = styled(Link)`
	font-size: 1.4rem;
	line-height: 2.2rem;
	font-weight: 500;
	color: ${Colors.gray[7]};
	margin-bottom: 1rem;

	@media ${MediaQueries.phone} {
		margin-bottom: 2rem;
		text-align: center;
	}
`;
