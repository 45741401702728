import React from 'react';

import solution from 'data/solution.json';
import { Colors } from 'environment';

import {
	Container,
	Content,
	Title,
	Features,
	Subtitle,
	Section,
	SectionFeature
} from './SolutionFeatures.style';

export function SolutionFeatures() {
	return (
		<Container id="features">
			<Section backgroundColor={Colors.gray[1]}>
				<Content>
					<Title>{solution.devices.title}</Title>
					<Subtitle>{solution.devices.subtitle}</Subtitle>
					<Features>
						{solution.devices.items.map((items, i) => (
							<SectionFeature key={`feature-${i}`} {...items} />
						))}
					</Features>
				</Content>
			</Section>
			<Section>
				<Content>
					<Subtitle>{solution.dashboard.title}</Subtitle>
					<Features>
						{solution.dashboard.items.map((items, i) => (
							<SectionFeature key={`feature-${i}`} {...items} />
						))}
					</Features>
				</Content>
			</Section>
		</Container>
	);
}
