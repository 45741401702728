import React, { useEffect, useState } from 'react';

import { links } from 'data/header.json';
import { logo, logoAlt } from 'data/landing.json';
import { Icons } from 'environment';
import { useSpring, useWindowSize } from 'hooks';

import { Container, Content, Close, Link, ImageLink } from './HeaderMenu.style';

interface Props {
	open: boolean;
	onClose: () => void;
}

export function HeaderMenu({ open, onClose }: Props) {
	const { width } = useWindowSize();

	const [visible, setVisible] = useState(open);

	useEffect(() => {
		let timeout: number;

		if (open) {
			setVisible(true);
		} else {
			timeout = setTimeout(() => setVisible(false), 300);
		}

		return () => clearTimeout(timeout);
	}, [open]);

	const { left } = useSpring({
		left: open ? 0 : -width
	});

	return (
		<Container style={{ left, opacity: visible ? 1 : 0 }}>
			<Content>
				<Close onClick={onClose}>
					<img src={`/images/${Icons.Close}`} alt="Close icon" />
				</Close>
				<ImageLink to="/" onClick={onClose}>
					<img src={`/images/${logo}`} alt={logoAlt} />
				</ImageLink>
				<Link activeClassName="active-link" to="/solution/" onClick={onClose}>
					{links.solution}
				</Link>
				<Link activeClassName="active-link" to="/case-studies/" onClick={onClose}>
					{links.studies}
				</Link>
				<Link activeClassName="active-link" to="/about-us/" onClick={onClose}>
					{links.about}
				</Link>
				<Link activeClassName="active-link" to="/blog/" onClick={onClose}>
					{links.blog}
				</Link>
				<Link activeClassName="active-link" to="/pricing/" onClick={onClose}>
					{links.pricing}
				</Link>
				<Link activeClassName="active-link" to="/contact/" onClick={onClose}>
					{links.contact}
				</Link>
				<Link to="/privacy/" activeClassName="active-link" onClick={onClose}>
					{links.privacy}
				</Link>
				<Link to="/terms/" activeClassName="active-link" onClick={onClose}>
					{links.terms}
				</Link>
				<Link to="/press/" activeClassName="active-link" onClick={onClose}>
					{links.press}
				</Link>
			</Content>
		</Container>
	);
}
