import { extend } from 'lodash';
import React from 'react';
import { defaultRules, parserFor, DefaultInRule } from 'simple-markdown';

const uRule: DefaultInRule = {
	order: defaultRules.strong.order - 1,
	match: function (source) {
		return /^`([\s\S]+?)`/.exec(source);
	},
	parse: function (capture, parse, state) {
		return {
			content: parse(capture[1], state)
		};
	},
	react: function (node, output) {
		return React.createElement('u', null, output(node.content));
	},
	html: null
};

const rules = extend({}, defaultRules, { u: uRule });
const parser = parserFor(rules);

export function useMarkdownParsing() {
	function parse(source: string) {
		const blockSource = source + '\n\n';
		return parser(blockSource, { inline: false });
	}

	return parse;
}
