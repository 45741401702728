import styled from 'styled-components';

import {
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: ${Colors.indigo};
`;

interface Props {
	video: boolean;
}

export const Content = styled.div<Props>`
	display: flex;
	flex-direction: column;
	justify-content: ${({ video }) => video && 'flex-end'};
	align-items: center;
	padding: 12rem 0rem;
	width: 100%;
	position: relative;

	@media ${MediaQueries.phone} {
		min-height: ${({ video }) => video && '48rem'};
		padding: 6rem 2rem;
	}

	@media ${MediaQueries.tablet} {
		min-height: ${({ video }) => video && '72rem'};
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		min-height: ${({ video }) => video && '85rem'};
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const Title = styled.h2`
	color: ${Colors.white};
	margin-bottom: 4rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;
	}
`;

export const Description = styled.p`
	color: ${Colors.gray[1]};
	max-width: 100%;
	text-align: center;

	@media ${MediaQueries.desktop} {
		max-width: ${6 * DESKTOP_GRID_COLUMN + 5 * DESKTOP_GRID_SPACING}rem;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 4rem;
	width: 100%;

	@media ${MediaQueries.phone} {
		margin-top: 2rem;
	}
`;
