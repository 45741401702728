import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import { WINDOW_EXISTS } from 'consts';
import analytics from 'data/analytics.json';
import content from 'data/cookie-modal.json';
import socialConfig from 'data/social.json';
import { useEffectOnce, useSpring, useModal } from 'hooks';

import { CookieModal } from '../Modal';
import {
	AnimatedContainer,
	Button,
	Controls,
	Content,
	Link,
	Row,
	Text
} from './CookieConsent.style';

const today = new Date();
const expires = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

export function CookieConsent() {
	const [modalProps, openModal] = useModal();
	const [open, setOpen] = useState(false);
	const [rendered, setRendered] = useState(false);

	useEffectOnce(() => {
		const cookieConsent = Cookies.get('cookie-consent');

		if (!cookieConsent) {
			setRendered(true);
		}
	});

	useEffect(() => {
		if (rendered && !open) {
			setOpen(true);
		}
	}, [rendered]);

	useEffect(() => {
		let timeout: number;

		if (!open && rendered) {
			timeout = setTimeout(() => setRendered(false), 500);
		}

		return () => clearTimeout(timeout);
	}, [open]);

	function handleAccept() {
		Cookies.set('cookie-consent', 'true', {
			expires
		});
		setOpen(false);
	}

	function handleUpdate(on: boolean) {
		let cookieValue = 'false';

		if (on) {
			cookieValue = 'true';
		} else {
			if (WINDOW_EXISTS) {
				// @ts-ignore
				window[`ga-disable-${socialConfig.googleAnalyticsID}`] = true;
			}
		}

		Cookies.set('cookie-consent', cookieValue, {
			expires
		});
		setOpen(false);
	}

	const style = useSpring({
		bottom: open ? 0 : -120
	});

	if (!rendered) {
		return null;
	}

	return (
		<>
			<CookieModal {...modalProps} onUpdate={handleUpdate} />
			<AnimatedContainer style={style}>
				<Content>
					<Row>
						<img src="/images/cookie.svg" alt="Cookie icon" />
						<Text>
							{content.banner.description}{' '}
							<Link to="/cookies/">{content.banner.cta}</Link>
						</Text>
					</Row>
					<Controls>
						<Button transparent onClick={openModal}>
							{content.banner.settings}
						</Button>
						<Button name={analytics.cookieAccept} outline onClick={handleAccept}>
							{content.banner.accept}
						</Button>
					</Controls>
				</Content>
			</AnimatedContainer>
		</>
	);
}
