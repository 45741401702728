import Img from 'gatsby-image';
import styled from 'styled-components';

import { MediaQueries } from 'environment';

interface ContainerProps {
	reverse: boolean;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 1;
	width: 100%;
	margin-bottom: 8rem;

	@media ${MediaQueries.desktop} {
		flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	}
`;

export const Image = styled(Img)`
	width: 100%;

	img {
		object-fit: contain !important;
	}

	@media ${MediaQueries.tablet} {
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		width: 45%;
	}
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.desktop} {
		width: 45%;
		align-items: flex-start;
	}
`;

export const Category = styled.span`
	margin-bottom: 1rem;

	@media ${MediaQueries.phone} {
		margin-top: 2rem;
	}
`;

export const Title = styled.h3`
	margin-bottom: 2rem;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
	}

	@media ${MediaQueries.tablet} {
		font-family: 'Open Sans';
		font-weight: 600;
	}
`;

export const Description = styled.p`
	text-align: center;

	@media ${MediaQueries.desktop} {
		text-align: start;
	}
`;
