export * from './team';

interface Pair {
	viewport?: boolean;
	value: boolean;
}

export function shouldInvertColor(pairs: Pair[]) {
	let invert = true;

	pairs.forEach(pair => {
		if (!pair.viewport || !pair.value) {
			invert = false;
		}
	});

	return invert;
}

export function throttle<T extends (...args: any[]) => void>(
	func: T,
	threshold: number = 250,
	scope?: any
): T {
	let last: number, deferTimer: number;

	return function (this: any) {
		let context = scope || this;

		let now = Date.now(),
			args = arguments;
		if (last && now < last + threshold) {
			clearTimeout(deferTimer);
			deferTimer = setTimeout(function () {
				last = now;
				func.apply(context, [...args]);
			}, threshold);
		} else {
			last = now;
			func.apply(context, [...args]);
		}
	} as T;
}

export function markdownToHTMLParser(node: string) {
	return node
		.split('<h3')
		.filter(e => e)
		.map(e => `<h3 ${e}`);
}
