import { useCallback, useEffect, useMemo, useRef } from 'react';

export function useCallbackOnce(callback: (...args: any[]) => any) {
	return useCallback(callback, []);
}

export function useEffectOnce(effect: React.EffectCallback) {
	useEffect(effect, []);
}

export function useMemoOnce<T>(factory: () => T) {
	return useMemo<T>(factory, []);
}

export const usePrevious = <T>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => void (ref.current = value), [value]);

	return ref.current;
};

export function useOutsideClick(listener: EventListenerOrEventListenerObject) {
	useEffectOnce(() => {
		document.addEventListener('click', listener);
		return () => document.removeEventListener('click', listener);
	});
}
