import { createContext } from 'react';

export interface CarouselContextProps {
	activeIndex: number;
	count: number;
	deltaX: number;
	fullScreen: boolean;
	prevActiveIndex: number;
	snapping: boolean;
	onChange?: (index: number) => void;
}

export const CarouselContext = createContext<CarouselContextProps>({
	activeIndex: 0,
	count: 0,
	deltaX: 0,
	fullScreen: false,
	prevActiveIndex: 0,
	snapping: false
});
