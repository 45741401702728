import React from 'react';
import { SingleASTNode } from 'simple-markdown';
import styled from 'styled-components';

import { useMarkdownParsing } from 'hooks';

function joinContent(tree: SingleASTNode[]): string {
	return tree.reduce((result, node) => {
		if (Array.isArray(node)) {
			return result + joinContent(node);
		}

		return result + node.content;
	}, '');
}

interface TextProps {
	children: string | React.ReactElement | React.ReactElement[];
}

interface Props {
	children: React.ReactElement<TextProps>;
	className?: string;
}

export function RichText({ children, className }: Props) {
	const parse = useMarkdownParsing();

	function recursivelyRenderChildren(
		tree: SingleASTNode[],
		type?: string,
		target?: string
	): React.ReactElement | React.ReactElement[] {
		if (type === 'link') {
			const text = joinContent(tree);
			return React.createElement(
				'a',
				{ key: `rich-text-link-${text}`, href: target, target: '_blank' },
				text
			);
		}

		return tree.map((node, i) => {
			if (type === 'strong' || type === 'em' || type === 'u') {
				if (Array.isArray(node.content)) {
					return React.createElement(
						type,
						{ key: `rich-text-${i}` },
						recursivelyRenderChildren(node.content, node.type)
					);
				}

				return React.createElement(type, { key: `rich-text-${i}` }, node.content);
			}

			if (Array.isArray(node.content)) {
				return recursivelyRenderChildren(node.content, node.type, node.target);
			}

			return node.content;
		});
	}

	const enhancedChildren = React.Children.map(children, child => {
		const syntaxTree = parse(child.props.children as string);
		return React.cloneElement(child, {
			children: recursivelyRenderChildren(syntaxTree)
		});
	});

	return <Container className={className}>{enhancedChildren}</Container>;
}

const Container = styled.span`
	color: inherit;

	a {
		border-bottom: 0.2rem transparent solid;
		color: #00ce7c;
		font-size: inherit;
		transition: border-color 0.2s;

		&:hover {
			border-color: #00ce7c;
		}
	}
`;
