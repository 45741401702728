import { MEMBER_IMAGE_HEIGHT, MEMBER_IMAGE_MOBILE_HEIGHT } from 'consts';

const MEMBER_PAIR_SPACING = 200;

// We need to do rem to px conversion here
function getImageHeight(mobile: boolean) {
	if (mobile) {
		return MEMBER_IMAGE_MOBILE_HEIGHT * 10;
	}

	return MEMBER_IMAGE_HEIGHT * 10;
}

export function getMembersHeight(count: number, isDesktop: boolean): number | 'auto' {
	if (!isDesktop) {
		return 'auto';
	}

	if (count % 2 !== 0) {
		return (
			Math.round(count / 2) * (getImageHeight(false) + MEMBER_PAIR_SPACING) -
			MEMBER_PAIR_SPACING
		);
	}

	return (count / 2) * (getImageHeight(false) + MEMBER_PAIR_SPACING);
}

export function getMemberTopPosition(index: number, isMobile: boolean, isDesktop: boolean): number {
	if (!isDesktop) {
		return 0;
	}

	if (index === 0) {
		return 0;
	}

	if (index % 4 === 3 || index % 4 === 1) {
		return getMemberTopPosition(index - 1, isMobile, isDesktop) + MEMBER_PAIR_SPACING;
	}

	if (index % 4 === 2 || index % 4 === 0) {
		return getMemberTopPosition(index - 1, isMobile, isDesktop) + getImageHeight(isMobile);
	}

	return 0;
}

export function getMemberSocialAbb(name: string) {
	switch (name) {
		case 'GitHub':
			return 'GIT';
		case 'LinkedIn':
			return 'IN';
		case 'Facebook':
			return 'FB';
		default:
			return 'GIT';
	}
}
