import React from 'react';

import { RichText } from 'components/UI';

import { Container, Description } from './BusinessItem.style';

interface Props {
	icon: {
		src: string;
		alt: string;
	};
	className?: string;
	description: string;
	light?: boolean;
}

export function BusinessItem({ className, icon, description, light = false }: Props) {
	return (
		<Container className={className}>
			<img src={`/images/${icon.src}`} alt={icon.alt} />
			<RichText>
				<Description light={light}>{description}</Description>
			</RichText>
		</Container>
	);
}
