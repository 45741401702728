import { Link as BaseLink } from 'gatsby';
import { animated } from 'react-spring';
import styled from 'styled-components';

import { TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

const BaseContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100vh;
	background-color: ${Colors.white};
`;
export const Container = animated(BaseContainer);

export const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	@media ${MediaQueries.phone} {
		justify-content: center;
		padding: 2rem;
	}

	@media (max-height: 800px) {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		justify-content: center;
		max-width: ${TABLET_GRID_SIZE}rem;
	}
`;

export const Link = styled(BaseLink)`
	color: ${Colors.indigo};
	font-family: 'Comfortaa';
	font-size: 2.4rem;
	font-weight: 600;
	margin-top: 4rem;
	transition: color 0.2s;

	&:first-of-type {
		margin-top: 8rem;
	}

	&.active-link {
		color: ${Colors.green};
	}

	@media (max-height: 800px) {
		font-size: 1.8rem;
		margin: 0.5rem 0;
	}

	@media ${MediaQueries.tablet} {
		margin: 2rem 0;
	}
`;

export const Close = styled.div`
	position: absolute;
	right: 2rem;
	top: 4rem;

	@media ${MediaQueries.tablet} {
		right: 0;
	}
`;

export const ImageLink = styled(BaseLink)`
	height: 4rem;
	position: absolute;
	left: 2rem;
	top: 4rem;

	img {
		height: 100%;
	}
`;
