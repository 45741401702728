import React from 'react';

import { business } from 'data/solution.json';
import analytics from 'data/analytics.json';

import { Button, RichText } from 'components/UI';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';
import {
	Container,
	Title,
	Content,
	Conclusion,
	Subtitle,
	ButtonContainer,
	SolutionBusinessItem
} from './SolutionBusiness.style';

export function SolutionBusiness() {
	const isPhone = useMediaQuery(MediaQueries.phone);

	return (
		<Container>
			<Title>{business.title}</Title>
			<Subtitle>{business.subtitle}</Subtitle>
			<Content>
				{business.items.map((item, i: number) => (
					<SolutionBusinessItem {...item} key={`business-item-${i}`} light />
				))}
				<RichText>
					<Conclusion>{business.conclusion}</Conclusion>
				</RichText>
				<ButtonContainer>
					<Button
						name={analytics.solutionBusinessGetInTouch}
						to="/contact/"
						width={isPhone ? '100%' : 38}
					>
						{business.cta}
					</Button>
				</ButtonContainer>
			</Content>
		</Container>
	);
}
