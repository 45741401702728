import styled, { css } from 'styled-components';

import { Colors } from 'environment';
import { Input as BaseInput } from './style';

interface Props {
	open: boolean;
}

export const Input = styled(BaseInput)`
	cursor: pointer;

	:hover {
		border-color: ${Colors.gray[7]};
	}
`;

export const Icon = styled.div<Props>`
	cursor: pointer;
	height: 4rem;
	position: absolute;
	right: 0;
	top: 3.5rem;
	transition: transform 0.25s;

	${({ open }) =>
		open &&
		css`
			transform: rotate(180deg);
		`}
`;

export const Menu = styled.div`
	background-color: ${Colors.white};
	display: flex;
	width: 100%;
	flex-direction: column;
	position: absolute;
	top: 8.1rem;
	z-index: 1;
`;
