import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { Carousel } from 'components/UI';
import { Image as ImageType } from 'types';

import { Image } from './ImageGallery.style';

interface Props {
	images: ImageType[];
	open?: boolean;
	onClose?: () => void;
}

export function ImageGallery({ images, open = false, onClose }: Props) {
	const [isOpen, setIsOpen] = useState(open);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		if (!isOpen) {
			onClose && onClose();
		}
	}, [isOpen]);

	function handleClose(e: React.MouseEvent) {
		setIsOpen(false);
	}

	if (!isOpen) {
		return null;
	}

	return ReactDOM.createPortal(
		<Carousel fullScreen onClose={handleClose}>
			{images.map((image, i) => (
				<Carousel.Item key={`landing-case-${i}`}>
					<>
						{image && <Image fluid={image.fluid} imgStyle={{ objectFit: 'contain' }} />}
					</>
				</Carousel.Item>
			))}
		</Carousel>,
		document.body
	);
}
