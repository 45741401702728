import { animated } from 'react-spring';
import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

import { Button as BaseButton } from '../Button';
import { TextLink } from '../TextLink';

const Container = styled.div`
	background-color: ${Colors.indigo};
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 12rem;
	position: fixed;
	width: 101%;
	bottom: 0;

	@media ${MediaQueries.phone} {
		height: auto;
		width: 100%;
	}
`;
export const AnimatedContainer = animated(Container);

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 2rem;
		flex-direction: column;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		align-items: center;
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	flex: 1;

	@media ${MediaQueries.phone} {
		margin-bottom: 1rem;
	}
`;

export const Text = styled.p`
	color: ${Colors.gray[5]};
	font-size: 1.4rem;
	line-height: 2.2rem;

	@media ${MediaQueries.tablet} {
		max-width: 32rem;
	}
`;

export const Link = styled(TextLink)`
	font-size: 1.4rem;
`;

export const Controls = styled.div`
	display: flex;
	align-items: center;

	@media ${MediaQueries.tablet} {
		margin-left: 3rem;
	}

	@media ${MediaQueries.desktop} {
		margin-left: 2rem;
	}
`;

export const Button = styled(BaseButton)`
	height: 4rem;

	:last-of-type {
		margin-left: 2rem;
	}
`;
