import React from 'react';

import { Button } from 'components/UI';
import analytics from 'data/analytics.json';
import { useQueryContext } from 'hooks';

import { Banner, Container, Content, Description, Title } from './BannerCta.style';

interface Props {
	title: string;
	description: string;
	label: string;
}

export function BannerCta({ title, description, label }: Props) {
	const { images } = useQueryContext();

	return (
		<Container>
			{images.banners.landing && <Banner image={images.banners.landing} />}
			<Content>
				<Title>{title}</Title>
				<Description>{description}</Description>
				<Button name={analytics.landingContactLetsTalk} to="/contact/" width={28}>
					{label}
				</Button>
			</Content>
		</Container>
	);
}
