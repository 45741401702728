import styled from 'styled-components';

import { Banner as BaseBanner } from 'components/UI/Banner';
import { SolutionFeature as BaseSolutionFeature } from 'components/UI/SolutionFeature';
import {
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const Content = styled.div`
	width: 100%;
	padding-top: 4rem;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
		padding-top: 4rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const Banner = styled(BaseBanner)`
	@media ${MediaQueries.phone} {
		height: 65rem;

		& > div {
			top: 50%;
		}
	}

	@media ${MediaQueries.tablet} {
		height: 65rem;

		& > div {
			top: 50%;
		}
	}
`;

export const Title = styled.h1`
	text-align: center;
	margin-bottom: 12rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-weight: 600;
		font-size: 3.6rem;
		line-height: 5rem;
		margin-bottom: 6rem;
	}
`;

export const SolutionFeature = styled(BaseSolutionFeature)`
	margin-bottom: 0;

	p,
	h3 {
		text-align: center;
	}

	@media ${MediaQueries.tablet} {
		div:first-child {
			margin-bottom: 0;
		}

		h3 {
			margin-bottom: 4rem;
		}
	}

	@media ${MediaQueries.desktop} {
		height: 40rem;

		div:first-child {
			width: ${6 * DESKTOP_GRID_COLUMN + 5 * DESKTOP_GRID_SPACING}rem;
		}

		div:last-child {
			width: ${6 * DESKTOP_GRID_COLUMN + 5 * DESKTOP_GRID_SPACING}rem;
		}

		h3 {
			margin-bottom: 4rem;
		}

		p,
		h3 {
			text-align: start;
		}
	}
`;
