import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

import { BusinessItem } from 'components/UI';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;
	background-color: ${Colors.indigo};

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
	}
`;

export const Title = styled.h1`
	margin-bottom: 4rem;
	text-align: center;
	color: ${Colors.gray[1]};
	font-weight: bold;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 3.6rem;
		line-height: 5rem;
		margin-bottom: 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Subtitle = styled.h3`
	font-family: 'Open Sans';
	color: ${Colors.green};
	font-weight: 600;
	margin-bottom: 8rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Comfortaa';
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 2.9rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Conclusion = styled.h3`
	font-family: 'Open Sans';
	font-weight: 600;
	margin-top: 6rem;
	margin-bottom: 8rem;
	text-align: center;
	color: ${Colors.gray[1]};

	@media ${MediaQueries.phone} {
		font-family: 'Comfortaa';
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin: 4rem 0;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const SolutionBusinessItem = styled(BusinessItem)`
	@media ${MediaQueries.phone} {
		margin-bottom: 1rem;

		p {
			margin-left: 2rem;
		}
	}
`;
