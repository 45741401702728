import styled from 'styled-components';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
		top: -15rem;
	}

	@media ${MediaQueries.tablet} {
		top: -17rem;
	}

	@media ${MediaQueries.desktop} {
		top: -35rem;
	}
`;

export const Video = styled.video`
	outline: none;
	width: 100%;
`;
