import Cookies from 'js-cookie';
import React from 'react';

import socialConfig from './src/data/social.json';
import { WINDOW_EXISTS } from './src/consts';
import { ContentProvider } from './src/components/Providers';
import { Footer, Header, Layout } from './src/components/UI';

export const onClientEntry = () => {
	window[`ga-disable-${socialConfig.googleAnalyticsID}`] = false;
	const cookie = Cookies.get('cookie-consent');

	if (WINDOW_EXISTS) {
		if (cookie) {
			const consent = JSON.parse(cookie);

			if (!consent) {
				window[`ga-disable-${socialConfig.googleAnalyticsID}`] = true;
			}
		}
	}
};

export function wrapPageElement({ element }) {
	return (
		<Layout>
			<Header />
			{element}
			<Footer />
		</Layout>
	);
}

export const wrapRootElement = ({ element }) => {
	return <ContentProvider>{element}</ContentProvider>;
};
