import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';
import { ResponsiveImage } from 'types';

interface Props {
	src?: string;
	image?: ResponsiveImage;
	height?: number;
	rotate?: number;
	scaleX?: number;
	top?: number;
	className?: string;
}

const Image = styled.div<Props>`
	position: absolute;
	top: ${({ top = 0 }) => top}px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 40vw;
	transform: ${({ rotate = 0, scaleX = 1 }) => `rotate(${rotate}deg) scaleX(${scaleX})`};
	background-image: ${({ src }) => `url(${src})`};
	background-position-x: 50%;
	background-size: cover;
	z-index: -1;

	@media ${MediaQueries.desktop} {
		background-position-x: 0;
	}
`;

const Container = styled.div<Props>`
	position: absolute;
	transform: ${({ rotate = 0, scaleX = 1 }) => `rotate(${rotate}deg) scaleX(${scaleX})`};
	width: 100%;
	z-index: -1;
`;

export function Banner({ src, image, height, rotate, scaleX, top, className }: Props) {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	if (image) {
		return (
			<Container rotate={rotate} scaleX={scaleX} className={className}>
				{isPhone && image.mobile && (
					<Img draggable={false} fluid={image.mobile.fluid} alt="Banner" />
				)}
				{isTablet && image.tablet && (
					<Img draggable={false} fluid={image.tablet.fluid} alt="Banner" />
				)}
				{isDesktop && image.desktop && (
					<Img draggable={false} fluid={image.desktop.fluid} alt="Banner" />
				)}
			</Container>
		);
	}

	if (src) {
		return (
			<Image
				src={src}
				height={height}
				rotate={rotate}
				scaleX={scaleX}
				top={top}
				className={className}
			/>
		);
	}

	return null;
}
