import { animated } from 'react-spring';
import styled from 'styled-components';
import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
`;

export const Circle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.1rem;
	width: 3.1rem;
	border-radius: 50%;
	border: 0.25rem ${Colors.green} solid;
	cursor: pointer;
`;

export const Bar = styled(animated.div)`
	position: absolute;
	height: 1.5rem;
	width: 0.3rem;
	border-radius: 0.15rem;
	background-color: ${Colors.green};
`;
