import styled from 'styled-components';
import { Colors } from 'environment';

interface Props {
	error?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
`;

export const Input = styled.input<Props>`
	width: 100%;
	height: 6rem;
	font-size: 1.8rem;
	font-family: 'Open Sans';
	padding: 0 1.6rem;
	color: ${Colors.indigo};
	background-color: transparent;
	border: none;
	border-bottom: ${({ error }) => `0.1rem ${error ? Colors.danger : Colors.gray[7]} solid`};
	border-radius: 0;
	outline: none;
	transition: border-color 0.2s;

	::placeholder {
		color: ${Colors.gray[5]};
	}

	:focus,
	:active {
		border-color: ${({ error }) => (error ? Colors.danger : Colors.green)};
	}

	:hover {
		border-color: ${({ error }) => (error ? Colors.danger : Colors.green)};
	}
`;

export const Textarea = styled.textarea<Props>`
	display: flex;
	min-width: 100%;
	max-width: 100%;
	width: 100%;
	min-height: 6rem;
	height: 6rem;
	font-size: 1.8rem;
	font-family: 'Open Sans';
	padding: 1.6rem;
	color: ${Colors.indigo};
	background-color: transparent;
	border: none;
	border-bottom: ${({ error }) => `0.1rem ${error ? Colors.danger : Colors.gray[7]} solid`};
	border-radius: 0;
	outline: none;
	transition: border-color 0.2s;

	::placeholder {
		color: ${Colors.gray[5]};
	}

	:focus,
	:active {
		border-color: ${({ error }) => (error ? Colors.danger : Colors.green)};
	}
`;

export const Error = styled.span`
	position: absolute;
	color: ${Colors.danger};
	bottom: -2.5rem;
`;
