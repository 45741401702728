import React from 'react';

import { Container, Description, Icon, Title } from './IconFeature.style';

interface Props {
	icon: string;
	className?: string;
	title: string;
	description?: string;
}

export function IconFeature({ icon, className, title, description }: Props) {
	return (
		<Container className={className}>
			<Icon src={`/images/${icon}`} />
			<Title>{title}</Title>
			{description && <Description>{description}</Description>}
		</Container>
	);
}
