import React, { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { EventData, Swipeable } from 'react-swipeable';

import { Icons, MediaQueries } from 'environment';
import { useWindowSize, useMediaQuery } from 'hooks';

import { CarouselDots } from './CarouselDots';
import { CarouselItem } from './CarouselItem';
import { Container, Icon } from './Carousel.style';
import { CarouselContext } from './Carousel.types';

interface Props {
	autoPlay?: number;
	children: React.ReactElement | React.ReactElement[];
	containerRef?: React.RefObject<HTMLDivElement>;
	fullScreen?: boolean;
	swipeThreshold?: number;
	onChange?: (index: number) => void;
	onClose?: (e: React.MouseEvent) => void;
}

export function Carousel({
	autoPlay,
	children,
	containerRef,
	fullScreen = false,
	swipeThreshold = 0.3,
	onChange,
	onClose
}: Props) {
	const count = React.Children.count(children);

	const [deltaX, setDeltaX] = useState(0);
	const [hovering, setHovering] = useState(false);
	const [snapping, setSnapping] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [prevActiveIndex, setPrevActiveIndex] = useState(0);

	const isDesktop = useMediaQuery(MediaQueries.desktop);
	const size = useWindowSize();

	useEffect(() => {
		let timeout: number;

		if (autoPlay && !hovering) {
			timeout = setTimeout(() => {
				next();
			}, [autoPlay]);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [hovering, activeIndex]);

	useEffect(() => {
		if (!snapping) {
			setSnapping(true);
		}
	}, [activeIndex]);

	useEffect(() => {
		let timeout: number;

		if (snapping) {
			timeout = setTimeout(() => {
				setSnapping(false);
			}, [500]);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [snapping]);

	function handleChange(index: number) {
		if (onChange) {
			onChange(index);
		}

		setPrevActiveIndex(activeIndex);
		setActiveIndex(index);
	}

	function prev() {
		const newIndex = (activeIndex - 1 + count) % count;
		setPrevActiveIndex(activeIndex);
		setActiveIndex(newIndex);
	}

	function next() {
		const newIndex = (activeIndex + 1 + count) % count;
		setPrevActiveIndex(activeIndex);
		setActiveIndex(newIndex);
	}

	function handleMouseEnter() {
		if (!hovering) {
			setHovering(true);
		}
	}

	function handleMouseLeave() {
		if (hovering) {
			setHovering(false);
		}
	}

	function handleSwiping(e: EventData) {
		if (!snapping) {
			if (!isDesktop) {
				if (e.deltaY === 0) {
					setDeltaX(e.deltaX);
				}
			} else {
				setDeltaX(e.deltaX);
			}
		}
	}

	function handleSwiped(e: EventData) {
		if (!snapping) {
			if (e.deltaX > size.width * swipeThreshold) {
				next();
			}

			if (e.deltaX < -size.width * swipeThreshold) {
				prev();
			}

			setDeltaX(0);
		}
	}

	const enhancedChildren = React.Children.map(children, (child, index) =>
		React.cloneElement(child, {
			index
		})
	);

	if (fullScreen) {
		return (
			<RemoveScroll>
				<FocusLock>
					<Container
						ref={containerRef}
						fullScreen
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<Swipeable trackMouse onSwiping={handleSwiping} onSwiped={handleSwiped}>
							<CarouselContext.Provider
								value={{
									activeIndex,
									prevActiveIndex,
									count,
									deltaX,
									fullScreen,
									snapping,
									onChange: handleChange
								}}
							>
								{enhancedChildren}
							</CarouselContext.Provider>
							<Icon
								src={`/images/${Icons.CloseLight}`}
								alt="Close icon"
								style={{ top: 20, right: 20 }}
								onClick={onClose}
							/>
							{isDesktop && (
								<>
									<Icon
										src={`/images/${Icons.ArrowLeftLight}`}
										alt="Arrow left icon"
										style={{ left: 20 }}
										onClick={prev}
									/>
									<Icon
										src={`/images/${Icons.ArrowRightLight}`}
										alt="Arrow right icon"
										style={{ right: 20 }}
										onClick={next}
									/>
								</>
							)}
						</Swipeable>
					</Container>
				</FocusLock>
			</RemoveScroll>
		);
	}

	return (
		<Container
			ref={containerRef}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Swipeable trackMouse onSwiping={handleSwiping} onSwiped={handleSwiped}>
				<CarouselContext.Provider
					value={{
						activeIndex,
						prevActiveIndex,
						count,
						deltaX,
						fullScreen,
						snapping,
						onChange: handleChange
					}}
				>
					{enhancedChildren}
				</CarouselContext.Provider>
			</Swipeable>
		</Container>
	);
}

Carousel.Dots = CarouselDots;
Carousel.Item = CarouselItem;
