import styled from 'styled-components';

import { IconFeature } from 'components/UI/IconFeature';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

interface Props {
	backgroundColor?: string;
}

export const Section = styled.section<Props>`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: ${({ backgroundColor }) => backgroundColor ?? Colors.white};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Title = styled.h1`
	text-align: center;
	padding-bottom: 8rem;

	@media ${MediaQueries.phone} {
		font-size: 3.6rem;
		line-height: 5rem;
		font-family: 'Open Sans';
		font-weight: 600;
		padding-bottom: 4rem;
	}
`;

export const Subtitle = styled.h3`
	text-align: center;
	font-family: 'Open Sans';
	font-weight: 600;
	margin-bottom: 8rem;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;
		font-family: 'Comfortaa';
	}
`;

export const Features = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@media ${MediaQueries.tablet} {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;

export const SectionFeature = styled(IconFeature)`
	@media ${MediaQueries.desktop} {
		margin-bottom: 8rem;
	}

	@media ${MediaQueries.phone} {
		margin: 2rem 0;
	}

	@media ${MediaQueries.tablet} {
		max-width: 30rem;
	}
`;
