import styled from 'styled-components';
import { Colors, MediaQueries } from 'environment';

import { Button as BaseButton } from '../Button';

export const Container = styled.div``;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const Title = styled.h4`
	@media ${MediaQueries.phone} and (max-width: 374px) {
		font-size: 2rem;
		line-height: 2.7rem;
	}

	@media ${MediaQueries.phone} {
		max-width: calc(100% - 6rem);
	}
`;

export const Description = styled.p`
	color: ${Colors.gray[7]};
	font-size: 1.4rem;
	line-height: 2.2rem;
	margin-top: 2rem;
	margin-bottom: 4rem;
`;

export const Button = styled(BaseButton)`
	height: 4rem;

	:first-of-type {
		button {
			font-size: 1.4rem;
		}
	}

	button {
		height: 4rem;
	}
`;
