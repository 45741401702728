import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 12rem;
	min-height: calc(100vh - 12rem);
	overflow-x: hidden;
`;
