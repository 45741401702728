import React, { useContext } from 'react';

import { CarouselContext } from './Carousel.types';
import { Container, Dot } from './CarouselDots.style';

interface Props {
	onDotClick?: (index: number) => void;
}

export function CarouselDots({ onDotClick }: Props) {
	const { activeIndex, count, onChange } = useContext(CarouselContext);

	function handleClick(index: number) {
		onDotClick && onDotClick(index);
		onChange && onChange(index);
	}

	return (
		<Container>
			{new Array(count).fill(0).map((_, i) => (
				<Dot
					key={`carousel-dot-${i}`}
					active={activeIndex % count === i}
					onClick={() => handleClick(i)}
				/>
			))}
		</Container>
	);
}
