import { compareDesc, parse } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo } from 'react';

import { QueryContext, BLOG_POST_DATE_FORMAT } from 'consts';
import studiesContent from 'data/case-studies.json';
import landingContent from 'data/landing.json';
import solutionContent from 'data/solution.json';
import teamContent from 'data/team.json';
import {
	AllFiles,
	AllImageSharp,
	AllMarkdownRemark,
	ImageSharpEdge,
	MarkdownRemarkEdge,
	Image,
	ResponsiveImage,
	SiteData,
	StudyImages
} from 'types';

const CONTACT_BANNER = 'banner-contact';
const LANDING_BANNER = 'banner-landing';
const PRICING_BANNER = 'banner-pricing';
const IAQ_IN_SCHOOLS = 'iaq-in-schools';

function getPostList(edges: MarkdownRemarkEdge[]) {
	return edges
		.map(edge => ({
			id: edge.node.id,
			path: `/blog${edge.node.fields.slug}`,
			featuredImage: edge.node.frontmatter.featuredImage,
			featuredImage_alt: edge.node.frontmatter.featuredImage_alt,
			title: edge.node.frontmatter.title,
			date: edge.node.frontmatter.date,
			excerpt: edge.node.frontmatter.excerpt,
			author: edge.node.frontmatter.author,
			authorImage: edge.node.frontmatter.authorImage,
			authorImage_alt: edge.node.frontmatter.authorImage_alt,
			category: edge.node.frontmatter.category
		}))
		.sort((a, b) => {
			const dateA = parse(a.date, BLOG_POST_DATE_FORMAT, new Date());
			const dateB = parse(b.date, BLOG_POST_DATE_FORMAT, new Date());

			return compareDesc(dateA, dateB);
		});
}

function getImages(edges: ImageSharpEdge[]) {
	let contactBanner: ResponsiveImage = {
		mobile: null,
		tablet: null,
		desktop: null
	};
	let landingBanner: ResponsiveImage = {
		mobile: null,
		tablet: null,
		desktop: null
	};
	let pricingBanner: ResponsiveImage = {
		mobile: null,
		tablet: null,
		desktop: null
	};

	const landingHero: ResponsiveImage = {
		mobile: null,
		tablet: null,
		desktop: null
	};
	const landingSolution: Image[] = [];

	const solutionDevices: Image[] = [];
	let solutionDiagram: Image = null;

	const landingCases: ResponsiveImage[] = [];
	const studiesCases: StudyImages[] = [];
	const iaqInSchools: ResponsiveImage = {
		mobile: null,
		tablet: null,
		desktop: null
	};

	const members: Image[] = [];

	edges.forEach(edge => {
		const { originalName } = edge.node.fluid;

		if (edge.node.fluid.originalName === `${CONTACT_BANNER}-mobile.png`) {
			contactBanner.mobile = edge.node;
		} else if (edge.node.fluid.originalName === `${CONTACT_BANNER}-tablet.png`) {
			contactBanner.tablet = edge.node;
		} else if (edge.node.fluid.originalName === `${CONTACT_BANNER}-desktop.png`) {
			contactBanner.desktop = edge.node;
		}

		if (edge.node.fluid.originalName === `${LANDING_BANNER}-mobile.png`) {
			landingBanner.mobile = edge.node;
		} else if (edge.node.fluid.originalName === `${LANDING_BANNER}-tablet.png`) {
			landingBanner.tablet = edge.node;
		} else if (edge.node.fluid.originalName === `${LANDING_BANNER}-desktop.png`) {
			landingBanner.desktop = edge.node;
		}

		if (edge.node.fluid.originalName === `${PRICING_BANNER}-mobile.png`) {
			pricingBanner.mobile = edge.node;
		} else if (edge.node.fluid.originalName === `${PRICING_BANNER}-tablet.png`) {
			pricingBanner.tablet = edge.node;
		} else if (edge.node.fluid.originalName === `${PRICING_BANNER}-desktop.png`) {
			pricingBanner.desktop = edge.node;
		}

		if (edge.node.fluid.originalName === `${IAQ_IN_SCHOOLS}-mobile.png`) {
			iaqInSchools.mobile = edge.node;
		} else if (edge.node.fluid.originalName === `${IAQ_IN_SCHOOLS}-tablet.png`) {
			iaqInSchools.tablet = edge.node;
		} else if (edge.node.fluid.originalName === `${IAQ_IN_SCHOOLS}-desktop.png`) {
			iaqInSchools.desktop = edge.node;
		}

		if (edge.node.fluid.originalName === landingContent.hero.image.mobile) {
			landingHero.mobile = edge.node;
		} else if (edge.node.fluid.originalName === landingContent.hero.image.tablet) {
			landingHero.tablet = edge.node;
		} else if (edge.node.fluid.originalName === landingContent.hero.image.desktop) {
			landingHero.desktop = edge.node;
		}

		landingContent.solution.features.forEach(feature => {
			if (
				edge.node.fluid.originalName === feature.image ||
				`/images/${edge.node.fluid.originalName}` === feature.image
			) {
				landingSolution.push(edge.node);
			}
		});

		solutionContent.features.forEach((feature, i) => {
			if (
				edge.node.fluid.originalName === feature.image ||
				`/images/${edge.node.fluid.originalName}` === feature.image
			) {
				solutionDevices[i] = edge.node;
			}
		});

		if (
			edge.node.fluid.originalName === solutionContent.diagram.image ||
			`/images/${edge.node.fluid.originalName}` === solutionContent.diagram.image
		) {
			solutionDiagram = edge.node;
		}

		landingContent.cases.forEach((c, i) => {
			if (!landingCases[i]) {
				landingCases[i] = {
					mobile: null,
					tablet: null,
					desktop: null
				};
			}

			if (originalName === c.image.mobile || `/images/${originalName}` === c.image.mobile) {
				landingCases[i] = {
					...landingCases[i],
					mobile: edge.node
				};
			}

			if (originalName === c.image.tablet || `/images/${originalName}` === c.image.tablet) {
				landingCases[i] = {
					...landingCases[i],
					tablet: edge.node
				};
			}

			if (originalName === c.image.desktop || `/images/${originalName}` === c.image.desktop) {
				landingCases[i] = {
					...landingCases[i],
					desktop: edge.node
				};
			}
		});

		studiesContent.cases.forEach((c, i) => {
			if (!studiesCases[i]) {
				studiesCases[i] = {
					cover: {
						mobile: null,
						tablet: null,
						desktop: null
					},
					location: []
				};
			}

			if (originalName === c.image.mobile || `/images/${originalName}` === c.image.mobile) {
				studiesCases[i] = {
					...studiesCases[i],
					cover: {
						...studiesCases[i].cover,
						mobile: edge.node
					}
				};
			}

			if (originalName === c.image.tablet || `/images/${originalName}` === c.image.tablet) {
				studiesCases[i] = {
					...studiesCases[i],
					cover: {
						...studiesCases[i].cover,
						tablet: edge.node
					}
				};
			}

			if (originalName === c.image.desktop || `/images/${originalName}` === c.image.desktop) {
				studiesCases[i] = {
					...studiesCases[i],
					cover: {
						...studiesCases[i].cover,
						desktop: edge.node
					}
				};
			}

			c.gallery.images.forEach(image => {
				if (originalName === image || `/images/${originalName}` === image) {
					studiesCases[i] = {
						...studiesCases[i],
						location: [...studiesCases[i].location, edge.node]
					};
				}
			});
		});

		teamContent.members.forEach((member, i) => {
			if (
				originalName === member.image.name ||
				`/images/${originalName}` === member.image.name
			) {
				members[i] = edge.node;
			}
		});
	});

	return {
		banners: {
			contact: contactBanner,
			landing: landingBanner,
			pricing: pricingBanner
		},
		landing: {
			hero: landingHero,
			solution: landingSolution
		},
		solution: {
			devices: solutionDevices,
			diagram: solutionDiagram
		},
		cases: {
			landing: landingCases,
			studies: studiesCases,
			iaqInSchools
		},
		members
	};
}

interface Props {
	children: React.ReactNode;
}

interface ContentQueryData {
	site: SiteData;
	images: AllImageSharp;
	posts: AllMarkdownRemark;
	files: AllFiles;
}

export function ContentProvider({ children }: Props) {
	const { files, images: allImages, posts: blogPosts, site } = useStaticQuery<ContentQueryData>(
		CONTENT_QUERY
	);

	const images = useMemo(() => getImages(allImages.edges), [allImages]);
	const posts = useMemo(() => getPostList(blogPosts.edges), [blogPosts]);

	return (
		<QueryContext.Provider
			value={{
				files: files.edges,
				images,
				posts,
				site
			}}
		>
			{children}
		</QueryContext.Provider>
	);
}

const CONTENT_QUERY = graphql`
	query {
		site {
			siteMetadata {
				title
				author
				description
				language
				siteUrl
			}
		}
		images: allImageSharp {
			edges {
				node {
					fluid(quality: 85, maxWidth: 3840) {
						originalName
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
		posts: allMarkdownRemark(
			filter: { frontmatter: { entity: { eq: "post" } } }
			limit: 2000
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						tags
						featuredImage {
							childImageSharp {
								fluid(quality: 85, maxWidth: 3840) {
									originalName
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						featuredImage_alt
						imageSource
						date
						excerpt
						author
						authorImage {
							childImageSharp {
								fluid(quality: 85, maxWidth: 100) {
									originalName
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						authorImage_alt
						category
					}
					fields {
						slug
					}
				}
			}
		}
		files: allFile(filter: { extension: { eq: "zip" } }) {
			edges {
				node {
					publicURL
				}
			}
		}
	}
`;
