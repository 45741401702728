import Img from 'gatsby-image';
import styled from 'styled-components';

import { MediaQueries } from 'environment';

export const Image = styled(Img)`
	pointer-events: none;
	user-select: none;
	width: 100%;
	max-height: calc(100vh - 24rem);

	@media ${MediaQueries.desktop} {
		max-height: 100vh;
	}
`;
