import { animated } from 'react-spring';
import styled from 'styled-components';

import { TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Overlay = styled.div`
	background-color: ${Colors.modalOverlay};
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
`;

const Container = styled.div`
	background-color: ${Colors.white};
	border-radius: 0.8rem;
	position: relative;
	max-width: calc(100% - 4rem);
	max-height: calc(100% - 4rem);
	width: 100%;

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: 58rem;
	}
`;
export const AnimatedContainer = animated(Container);

export const Content = styled.div`
	padding: 4rem;
	overflow-x: hidden;
	overflow-y: auto;

	@media ${MediaQueries.phone} and (max-width: 374px) {
		padding: 4rem 2rem;
	}
`;
