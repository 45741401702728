import styled from 'styled-components';

import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.phone} {
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.tablet} {
		margin-bottom: 8rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_COLUMN * 4 + DESKTOP_GRID_SPACING + 3}rem;
	}
`;

export const Icon = styled.img`
	width: 18rem;
	height: 15rem;
`;

export const Title = styled.h4`
	text-align: center;
	margin-top: 4rem;
`;

export const Description = styled.p`
	text-align: center;
	margin-top: 2rem;
`;
