import React from 'react';
import Helmet from 'react-helmet';

import generalConfiguration from 'data/general.json';
import { useQueryContext } from 'hooks';

interface Props {
	title?: string;
	description?: string;
	meta?: HTMLMetaElement[];
	lang?: string;
	ogTitle?: string;
	ogDescription?: string;
	ogImage?: string;
}

export function SEO({
	title,
	description,
	meta = [],
	lang,
	ogTitle,
	ogDescription,
	ogImage
}: Props) {
	const {
		site: { siteMetadata }
	} = useQueryContext();

	const {
		title: defaultTitle,
		author,
		description: defaultDescription,
		language: defaultLanguage
	} = siteMetadata;

	const metaImage = `${generalConfiguration.siteUrl}${ogImage}`;
	const language = lang || defaultLanguage;

	return (
		<Helmet
			htmlAttributes={{
				language
			}}
			title={title || defaultTitle}
			titleTemplate={title || defaultTitle}
			meta={[
				{
					name: `author`,
					content: author
				},
				{
					name: `description`,
					content: description || defaultDescription
				},
				{
					property: `og:title`,
					content: ogTitle
				},
				{
					property: `og:description`,
					content: ogDescription || defaultDescription
				},
				{
					property: `og:image`,
					content: metaImage
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: author
				},
				{
					name: `twitter:title`,
					content: title
				},
				{
					name: `twitter:description`,
					content: description || defaultDescription
				},
				{
					name: `magicNumber`,
					content: 't1xW+mACvxDjqwOxPG7MpuU90Y2pFhMv8Ook9e7HU3w'
				}
			].concat(meta)}
		/>
	);
}
