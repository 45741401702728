import styled from 'styled-components';
import { animated } from 'react-spring';

const Container = styled.div`
	position: absolute;
	width: 100%;
	user-select: none;

	img {
		pointer-events: none;
	}
`;

export const AnimatedContainer = animated(Container);
