import React from 'react';

import { SolutionVideo } from 'components/Solution';
import { Button } from 'components/UI';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';

import { ButtonContainer, Content, Container, Description, Title } from './DarkCta.style';

interface Props {
	title: string | React.ReactElement;
	subtitle?: string;
	description?: string;
	cta: string;
	to: string;
	name?: string;
	video?: boolean;
}

export function DarkCta({ title, subtitle, name, description, cta, to, video = false }: Props) {
	const isPhone = useMediaQuery(MediaQueries.phone);

	return (
		<Container>
			<Content video={video}>
				{video && <SolutionVideo />}
				{typeof title === 'string' ? <Title>{title}</Title> : title}
				{subtitle && <Title>{subtitle}</Title>}
				{description && <Description>{description}</Description>}
				<ButtonContainer>
					<Button name={name} to={to} width={isPhone ? '100%' : 38}>
						{cta}
					</Button>
				</ButtonContainer>
			</Content>
		</Container>
	);
}
