import styled from 'styled-components';

import { Banner as BaseBanner } from 'components/UI/Banner';
import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 72rem;
	position: relative;
`;

export const Banner = styled(BaseBanner)`
	position: relative;

	@media ${MediaQueries.desktop} {
		min-height: unset;
	}
`;

export const Content = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 2rem;
	}
`;

export const Title = styled.h2`
	text-align: center;
	margin-bottom: 4rem;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		margin-bottom: 2rem;
	}
`;

export const Description = styled.p`
	text-align: center;
	margin-bottom: 8rem;

	@media ${MediaQueries.phone} {
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		margin-left: 1.6rem;
		margin-right: 1.6rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		margin-bottom: 2rem;
	}
`;
