export const Icons = {
	ArrowLeftLight: 'arrow-left-light.svg',
	ArrowRight: 'arrow-right.svg',
	ArrowRightLight: 'arrow-right-light.svg',
	Close: 'close.svg',
	CloseLight: 'close-light.svg',
	Menu: 'menu.svg',
	Facebook: 'facebook.svg',
	GitHub: 'github.svg',
	LinkedIn: 'linkedin.svg',
	YouTube: 'youtube.svg'
};
