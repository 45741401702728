import React from 'react';

import { RichText } from 'components/UI';
import { Image as ImageType } from 'types';

import { Category, Container, Details, Image, Title, Description } from './SolutionFeature.style';

interface Props {
	title: string;
	category?: string;
	description: string;
	image: ImageType;
	reverse: boolean;
	className?: string;
}

export function SolutionFeature({
	category,
	title,
	description,
	image,
	reverse,
	className
}: Props) {
	if (!image) {
		return null;
	}

	return (
		<Container reverse={reverse} className={className}>
			<Image fluid={image.fluid} />
			<Details>
				{category && <Category>{category}</Category>}
				<Title>{title}</Title>
				<RichText>
					<Description>{description}</Description>
				</RichText>
			</Details>
		</Container>
	);
}
