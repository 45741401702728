import styled from 'styled-components';

import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';
import { Colors, MediaQueries } from 'environment';

interface Props {
	light: boolean;
}

export const Container = styled.div`
	display: flex;
	margin-bottom: 2rem;

	@media ${MediaQueries.phone} {
		align-items: flex-start;
	}

	@media ${MediaQueries.desktop} {
		margin-left: ${2 * DESKTOP_GRID_COLUMN + 2 * DESKTOP_GRID_SPACING}rem;
		margin-right: ${2 * DESKTOP_GRID_COLUMN + 2 * DESKTOP_GRID_SPACING}rem;
	}
`;

export const Description = styled.p<Props>`
	margin-left: 4rem;
	color: ${({ light }) => light && Colors.gray[1]};
`;
