import { Link as BaseLink, GatsbyLinkProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Colors } from 'environment';

export function TextLink<State = any>(props: GatsbyLinkProps<State>) {
	// @ts-ignore
	return <Link {...props} />;
}

const Link = styled(BaseLink)`
	border-bottom: 0.2rem transparent solid;
	color: ${Colors.green};
	transition: border-color 0.2s;

	&:hover {
		border-color: ${Colors.green};
	}
`;
