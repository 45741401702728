import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import { Icons } from 'environment';
import { Container } from './IconButton.style';

interface Props {
	type: (i: typeof Icons) => string;
	alt?: string;
	name?: string;
	disabled?: boolean;
	onClick: () => void;
}

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
	({ type: getType, alt = 'Icon button', disabled, name, onClick }, ref) => {
		function handleClick() {
			onClick();
			trackCustomEvent({
				category: 'button',
				action: 'click',
				label: name
			});
		}

		const icon = getType(Icons);

		return (
			<Container ref={ref} disabled={disabled} onClick={handleClick}>
				<img src={`/images/${icon}`} alt={alt} />
			</Container>
		);
	}
);
