import { Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import React from 'react';
import { PulseLoader as Loader } from 'react-spinners';

import { Colors } from 'environment';
import { Button as BaseButton, OutboundLinkButton } from './Button.style';

interface Props {
	as?: string;
	children: React.ReactNode;
	to?: string;
	href?: string;
	state?: any;
	name?: string;
	loading?: boolean;
	width?: string | number;
	outline?: boolean;
	light?: boolean;
	transparent?: boolean;
	type?: 'button' | 'submit';
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
	(
		{
			children,
			width,
			to,
			href,
			state,
			name,
			loading = false,
			outline = false,
			light = false,
			transparent = false,
			disabled = false,
			type = 'button',
			className,
			onClick
		},
		ref
	) => {
		function handleClick() {
			trackCustomEvent({
				category: 'button',
				action: 'click',
				label: name
			});
			if (!loading && onClick) {
				onClick();
			}
		}

		if (to) {
			return (
				<Link
					to={to}
					state={state}
					className={className}
					style={{
						width: !width ? 'auto' : typeof width === 'string' ? width : `${width}rem`
					}}
				>
					<BaseButton
						ref={ref}
						outline={outline}
						transparent={transparent}
						light={light}
						loading={loading}
						disabled={disabled || loading}
						type={type}
						style={{
							outline: 'none',
							width: !width
								? 'auto'
								: typeof width === 'string'
								? width
								: `${width}rem`
						}}
						onClick={handleClick}
					>
						{loading ? <Loader color={Colors.white} /> : children}
					</BaseButton>
				</Link>
			);
		}

		if (href) {
			return (
				<OutboundLinkButton
					href={href}
					outline={outline}
					transparent={transparent}
					light={light}
					loading={loading}
					disabled={disabled || loading}
					style={{
						width: !width ? 'auto' : typeof width === 'string' ? width : `${width}rem`
					}}
					target="_blank"
					type={type}
					className={className}
					onClick={handleClick}
				>
					{loading ? <Loader color={Colors.white} /> : children}
				</OutboundLinkButton>
			);
		}

		return (
			<BaseButton
				ref={ref}
				outline={outline}
				transparent={transparent}
				light={light}
				loading={loading}
				disabled={disabled || loading}
				style={{
					width: !width ? 'auto' : typeof width === 'string' ? width : `${width}rem`
				}}
				type={type}
				className={className}
				onClick={handleClick}
			>
				{loading ? <Loader color={Colors.white} /> : children}
			</BaseButton>
		);
	}
);
