import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'environment';
import { Nullable } from 'types';

interface StyleProps {
	outline: boolean;
	light: boolean;
	transparent: boolean;
	loading: boolean;
}

interface ButtonProps extends StyleProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
	ref:
		| ((instance: Nullable<HTMLButtonElement>) => void)
		| React.MutableRefObject<Nullable<HTMLButtonElement>>
		| null;
}

const FilteredButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, outline, transparent, light, loading, ...props }, ref) => (
		<button ref={ref} {...props}>
			{children}
		</button>
	)
);

export const Button = styled(FilteredButton)`
	background-color: ${({ outline, transparent, light }) =>
		outline || transparent ? 'transparent' : light ? Colors.gray[1] : Colors.green};
	border: 0.2rem
		${({ light, transparent }) =>
			light ? Colors.gray[1] : transparent ? 'transparent' : Colors.green}
		solid;
	border-radius: 3rem;
	color: ${({ outline, transparent, light }) =>
		outline || transparent ? Colors.green : light ? Colors.indigo : Colors.white};
	height: 6rem;
	font-family: 'Open Sans';
	font-size: 1.8rem;
	font-weight: 600;
	padding: ${({ transparent }) => (transparent ? 0 : '0 4rem')};
	text-transform: ${({ transparent }) => !transparent && 'uppercase'};
	transition: border-color 0.2s;

	${({ loading }) =>
		!loading &&
		css`
			:disabled {
				opacity: 0.5;
			}

			:not(:disabled) {
				cursor: pointer;
			}
		`}

	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
`;

export const OutboundLinkButton = styled(OutboundLink)<StyleProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ outline, transparent, light }) =>
		outline || transparent ? 'transparent' : light ? Colors.gray[1] : Colors.green};
	border: 0.2rem
		${({ light, transparent }) =>
			light ? Colors.gray[1] : transparent ? 'transparent' : Colors.green}
		solid;
	border-radius: 3rem;
	color: ${({ outline, transparent, light }) =>
		outline || transparent ? Colors.green : light ? Colors.indigo : Colors.white};
	height: 6rem;
	font-family: 'Open Sans';
	font-size: 1.8rem;
	font-weight: 600;
	padding: ${({ transparent }) => (transparent ? 0 : '0 4rem')};
	text-transform: ${({ transparent }) => !transparent && 'uppercase'};
	transition: border-color 0.2s;

	${({ loading }) =>
		!loading &&
		css`
			:disabled {
				opacity: 0.5;
			}

			:not(:disabled) {
				cursor: pointer;
			}
		`}
`;
