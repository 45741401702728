import React from 'react';

import solution from 'data/solution.json';
import { useQueryContext } from 'hooks';

import { Banner, Container, Content, SolutionFeature, Title } from './SolutionHero.style';

export function SolutionHero() {
	const { images } = useQueryContext();

	return (
		<Container>
			{images.banners.pricing && <Banner image={images.banners.pricing} />}
			<Content>
				<Title>{solution.title}</Title>
				{solution.features.map((feature, i) => (
					<SolutionFeature
						{...feature}
						key={`${feature.title}-feature`}
						image={images.solution.devices[i]}
						reverse={i % 2 !== 0}
					/>
				))}
			</Content>
		</Container>
	);
}
