import React from 'react';
import 'simplebar/dist/simplebar.min.css';

import { WINDOW_EXISTS } from 'consts';

export const CustomScrollbar = React.forwardRef<any, any>((props, ref) => {
	if (!WINDOW_EXISTS) {
		return null;
	}

	const SimpleBar = require('simplebar-react').default;
	return <SimpleBar ref={ref} {...props} />;
});
