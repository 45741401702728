import React from 'react';

import solution from 'data/solution.json';
import { useQueryContext } from 'hooks';

import {
	Container,
	Content,
	DiagramTitle,
	Image,
	SolutionDiagramImage
} from './SolutionDiagram.style';

export function SolutionDiagram() {
	const { images } = useQueryContext();

	return (
		<Container>
			<Content>
				<DiagramTitle>{solution.diagram.title}</DiagramTitle>
			</Content>
			<SolutionDiagramImage>
				{images.solution.diagram && <Image fluid={images.solution.diagram.fluid} />}
			</SolutionDiagramImage>
		</Container>
	);
}
