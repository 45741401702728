import React from 'react';
import { AllFilesEdge, PostListItem, Image, ResponsiveImage, SiteData, StudyImages } from './types';

interface QueryContextData {
	files: AllFilesEdge[];
	images: {
		banners: {
			contact: ResponsiveImage;
			landing: ResponsiveImage;
			pricing: ResponsiveImage;
		};
		landing: {
			hero: ResponsiveImage;
			solution: Image[];
		};
		solution: {
			devices: Image[];
			diagram: Image;
		};
		cases: {
			landing: ResponsiveImage[];
			studies: StudyImages[];
			iaqInSchools: ResponsiveImage;
		};
		members: Image[];
	};
	posts: PostListItem[];
	site: SiteData;
}

export const QueryContext = React.createContext<QueryContextData>({
	files: [],
	images: {
		banners: {
			contact: {
				mobile: null,
				tablet: null,
				desktop: null
			},
			landing: {
				mobile: null,
				tablet: null,
				desktop: null
			},
			pricing: {
				mobile: null,
				tablet: null,
				desktop: null
			}
		},
		landing: {
			hero: {
				mobile: null,
				tablet: null,
				desktop: null
			},
			solution: []
		},
		solution: {
			devices: [],
			diagram: null
		},
		cases: {
			landing: [],
			studies: [],
			iaqInSchools: {
				mobile: null,
				tablet: null,
				desktop: null
			}
		},
		members: []
	},
	posts: [],
	site: {
		siteMetadata: {
			title: '',
			author: '',
			description: '',
			language: '',
			siteUrl: ''
		}
	}
});

export const BLOG_POST_DATE_FORMAT = 'MM/dd/yyyy';
export const PHONE_GRID_SIZE = 33.5;
export const TABLET_GRID_SIZE = 62;
export const DESKTOP_GRID_COLUMN = 8;
export const DESKTOP_GRID_SPACING = 2;
export const DESKTOP_GRID_SIZE = DESKTOP_GRID_COLUMN * 12 + DESKTOP_GRID_SPACING * 11;
export const MEMBER_IMAGE_WIDTH = 28;
export const MEMBER_IMAGE_HEIGHT = 42;
export const MEMBER_IMAGE_MOBILE_WIDTH = 14;
export const MEMBER_IMAGE_MOBILE_HEIGHT = 21;

export const DOCUMENT_EXISTS = typeof document !== 'undefined';
export const WINDOW_EXISTS = typeof window !== 'undefined';
