import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useFormik } from 'formik';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React, { useState } from 'react';
import * as yup from 'yup';

import { IconButton } from 'components/UI';
import socialConfiguration from 'data/social.json';
import apiMessages from 'data/api.json';
import footer from 'data/footer.json';
import analytics from 'data/analytics.json';
import { Icons } from 'environment';

import {
	Container,
	Content,
	Copyright,
	Column,
	Item,
	Link,
	Row,
	Separator,
	Socials,
	SocialIcon,
	TextInput,
	Success,
	Error,
	Title,
	UpdatesColumn,
	MailChimpConsent,
	MailChimpLabel,
	MailChimpLink
} from './Footer.style';

const validationSchema = yup.object().shape({
	email: yup.string().email(footer.updates.invalid).required(footer.updates.required)
});

export function Footer() {
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isValid,
		touched,
		validateForm,
		values
	} = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async values => {
			if (values.email) {
				const { email } = values;

				try {
					const res = await addToMailchimp(email);

					if (res.result === 'success') {
						setSuccess(true);
					} else if (res.result === 'error') {
						if (res.msg.includes('is already subscribed')) {
							setError(apiMessages.mailChimp.alreadySubscribed);
						}
						if (res.msg.includes('too many recent signup requests')) {
							setError(apiMessages.mailChimp.tooManyAttempts);
						}
					}
				} catch (error) {
					console.log(error);
					if (error.message) {
						setError(apiMessages.internalError);
					}
				}
			}
		}
	});

	function handleClick() {
		if (isValid) {
			handleSubmit();
		} else {
			validateForm();
		}
	}

	function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (error.length > 0) {
			setError('');
			handleChange(e);
		} else {
			handleChange(e);
		}
	}

	return (
		<Container>
			<Content>
				{footer.columns.map((column, index) => (
					<Column key={`column-${index}`}>
						<Title>{column.title}</Title>
						<Separator />
						{column.items.map((item, i) => {
							if (item.to) {
								return (
									<Link
										key={`column-${index}-item-${i}`}
										activeClassName="active-link"
										to={item.to}
									>
										{item.label}
									</Link>
								);
							}

							return <Item key={`column-${index}-item-${i}`}>{item.label}</Item>;
						})}
					</Column>
				))}
				<UpdatesColumn>
					<Title>{footer.updates.title}</Title>
					<Separator />
					<MailChimpConsent>
						<MailChimpLabel>{footer.updates.mailChimpConsent}&nbsp;</MailChimpLabel>
						<MailChimpLink activeClassName="active-link" to="/privacy">
							{footer.updates.mailChimpPrivacyPolicy}
						</MailChimpLink>
					</MailChimpConsent>
					<Row>
						<TextInput
							disabled={success}
							error={touched.email ? errors.email : undefined}
							name="email"
							placeholder={footer.updates.placeholder}
							value={values.email}
							onBlur={handleBlur}
							onChange={handleInputChange}
							onSubmit={handleClick}
						/>
						{error && <Error>{error}</Error>}
						{success && <Success>{apiMessages.mailChimp.subscribeSuccess}</Success>}
						<IconButton
							name={analytics.footerNewNewsletterSignUp}
							type={icons => icons.ArrowRight}
							alt="Arrow right button"
							disabled={!isValid || success}
							onClick={handleClick}
						/>
					</Row>
					<Socials>
						<OutboundLink
							href={socialConfiguration.facebookProfileLink}
							rel="nofollow noreferrer"
							target="_blank"
						>
							<SocialIcon src={`/images/${Icons.Facebook}`} />
						</OutboundLink>
						<OutboundLink
							href={socialConfiguration.youTubeProfileLink}
							rel="nofollow noreferrer"
							target="_blank"
						>
							<SocialIcon src={`/images/${Icons.YouTube}`} />
						</OutboundLink>
						<OutboundLink
							href={socialConfiguration.linkedInProfileLink}
							rel="nofollow noreferrer"
							target="_blank"
						>
							<SocialIcon src={`/images/${Icons.LinkedIn}`} />
						</OutboundLink>
						<OutboundLink
							href={socialConfiguration.gitHubProfileLink}
							rel="nofollow noreferrer"
							target="_blank"
						>
							<SocialIcon src={`/images/${Icons.GitHub}`} />
						</OutboundLink>
					</Socials>
				</UpdatesColumn>
			</Content>
			<Copyright>Copyright {new Date().getFullYear()} Sensix. All rights reserved.</Copyright>
		</Container>
	);
}
