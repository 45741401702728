import React, { useContext, useEffect, useState } from 'react';
import { useSpring } from 'react-spring';

import { DOCUMENT_EXISTS } from 'consts';
import { useWindowSize } from 'hooks';

import { CarouselContext } from './Carousel.types';
import { AnimatedContainer } from './CarouselItem.style';

interface Props {
	children: React.ReactElement;
	index?: number;
	active?: boolean;
}

export function CarouselItem({ children, index = 0 }: Props) {
	const [right, setRight] = useState(false);
	const { activeIndex, count, deltaX, fullScreen, prevActiveIndex, snapping } = useContext(
		CarouselContext
	);
	const size = useWindowSize();

	useEffect(() => {
		if (activeIndex === 0 && prevActiveIndex === count - 1) {
			setRight(true);
		} else if (activeIndex === count - 1 && prevActiveIndex === 0) {
			setRight(false);
		} else if (prevActiveIndex < activeIndex) {
			setRight(true);
		} else if (prevActiveIndex > activeIndex) {
			setRight(false);
		}
	}, [activeIndex]);

	function getScrollbarWidth() {
		if (fullScreen || !DOCUMENT_EXISTS) {
			return 0;
		}

		return size.width - document.documentElement.clientWidth;
	}

	let left = 0;
	if (activeIndex === index) {
		left = -deltaX;
	} else if ((activeIndex + 1 + count) % count === index) {
		left = size.width - deltaX - getScrollbarWidth();
	} else if ((activeIndex - 1 + count) % count === index) {
		left = -size.width - deltaX + getScrollbarWidth();
	}

	const position = useSpring({
		left,
		config:
			snapping &&
			((right && (activeIndex + 1 + count) % count === index) ||
				(!right && (activeIndex - 1 + count) % count === index))
				? {
						duration: 0
				  }
				: undefined
	});

	return (
		<AnimatedContainer
			style={{
				position: fullScreen ? 'absolute' : index === activeIndex ? 'relative' : 'absolute',
				top: fullScreen ? 'unset' : 0,
				left: position.left
			}}
		>
			{children}
		</AnimatedContainer>
	);
}
