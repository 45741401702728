import React from 'react';
import { useSpring } from 'hooks';

import { Bar, Container, Circle } from './ExpandableIcon.style';

interface Props {
	expanded: boolean;
	onClick: () => void;
}

export function ExpandableIcon({ expanded, onClick }: Props) {
	const horizontalBarAnimation = useSpring({
		transform: expanded ? 'rotate(180deg) scaleY(0)' : 'rotate(90deg) scaleY(1)'
	});
	const verticalBarAnimation = useSpring({
		transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)'
	});

	return (
		<Container>
			<Circle onClick={onClick}>
				<Bar style={horizontalBarAnimation} />
				<Bar style={verticalBarAnimation} />
			</Circle>
		</Container>
	);
}
