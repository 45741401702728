import { useEffect, useState } from 'react';

export function useMediaQuery(query: string, defaultMatches = false) {
	const [matches, setMatches] = useState(defaultMatches);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(query);
		let active = true;

		function listener() {
			if (!active) {
				return;
			}

			if (mediaQueryList.matches) {
				setMatches(true);
			} else {
				setMatches(false);
			}
		}

		mediaQueryList.addListener(listener);
		setMatches(mediaQueryList.matches);

		return () => {
			active = false;
			mediaQueryList.removeListener(listener);
		};
	}, [query]);

	return matches;
}
