import React, { useState } from 'react';

import { links } from 'data/header.json';
import { logo, logoAlt } from 'data/landing.json';
import { Icons, MediaQueries } from 'environment';
import { useMediaQuery, useSpring, useWindowScrollPosition } from 'hooks';

import { HeaderMenu } from './HeaderMenu';
import { AnimatedWrapper, Container, Image, ImageLink, Link, Nav } from './Header.style';

export function Header() {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	const [isHeaderHidden, setIsHeaderHidden] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useWindowScrollPosition(
		({ previous, current }) => {
			if (current.y > 60) {
				if (previous.y < current.y && !isHeaderHidden && !isMenuOpen) {
					setIsHeaderHidden(true);
				}
			}

			if (previous.y > current.y && isHeaderHidden) {
				setIsHeaderHidden(false);
			}
		},
		[isHeaderHidden, isMenuOpen]
	);

	function handleMenuOpen() {
		setIsMenuOpen(true);
	}

	function handleMenuClose() {
		setIsMenuOpen(false);
	}

	const animation = useSpring({
		top: isHeaderHidden ? -120 : 0
	});

	return (
		<AnimatedWrapper style={animation}>
			<Container>
				<ImageLink to="/">
					<Image src={`/images/${logo}`} alt={logoAlt} />
				</ImageLink>
				{isDesktop && (
					<Nav>
						<Link to="/solution/" activeClassName="active-link">
							{links.solution}
						</Link>
						<Link to="/case-studies/" activeClassName="active-link">
							{links.studies}
						</Link>
						<Link to="/about-us/" activeClassName="active-link">
							{links.about}
						</Link>
						<Link partiallyActive to="/blog/" activeClassName="active-link">
							{links.blog}
						</Link>
						<Link partiallyActive to="/pricing/" activeClassName="active-link">
							{links.pricing}
						</Link>
						<Link to="/contact/" activeClassName="active-link">
							{links.contact}
						</Link>
					</Nav>
				)}
				{(isPhone || isTablet) && (
					<Nav>
						<Image
							src={`/images/${Icons.Menu}`}
							alt="Menu icon"
							onClick={handleMenuOpen}
						/>
					</Nav>
				)}
			</Container>
			{(isPhone || isTablet) && <HeaderMenu open={isMenuOpen} onClose={handleMenuClose} />}
		</AnimatedWrapper>
	);
}
