import { useLayoutEffect, useRef } from 'react';
import { WINDOW_EXISTS } from 'consts';

interface Position {
	x: number;
	y: number;
}

type Effect = ({ previous, current }: { previous: Position; current: Position }) => void;

export function useWindowScrollPosition(
	effect: Effect,
	deps: React.DependencyList = [],
	wait = 100
) {
	const position = useRef({ x: 0, y: 0 });

	let timeout: number | null = null;

	const cb = () => {
		if (WINDOW_EXISTS) {
			const currentPosition = { x: window.scrollX, y: window.scrollY };
			effect({ previous: position.current, current: currentPosition });
			position.current = currentPosition;
			timeout = null;
		}
	};

	useLayoutEffect(() => {
		const handleScroll = () => {
			if (wait) {
				if (timeout === null) {
					timeout = setTimeout(cb, wait);
				}
			} else {
				cb();
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, deps);
}
