export const Colors = {
	indigo: '#243746',
	yellow: '#ffc629',
	white: '#fff',
	green: '#00ce7c',
	gray: {
		7: '#7e8d90',
		5: '#cedfd7',
		4: '#d9e7e0',
		1: '#f2f7f5'
	},
	william: '#3b5c70',
	black: '#000',
	danger: '#ef6363',
	galleryOverlay: 'rgba(0, 0, 0, 0.75)',
	modalOverlay: 'rgba(36, 55, 70, 0.95)'
};
