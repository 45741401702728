import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import analytics from 'data/analytics.json';

import { Container, Video } from './SolutionVideo.style';

export function SolutionVideo() {
	function captureVideoActions(action: string) {
		if (action === 'PLAY') {
			trackCustomEvent({
				category: 'Solution Video',
				action: 'Play',
				label: analytics.solutionVideo
			});
		}
		if (action === 'PAUSE') {
			trackCustomEvent({
				category: 'Solution Video',
				action: 'Pause',
				label: analytics.solutionVideo
			});
		}
	}

	return (
		<Container>
			<Video
				controls
				controlsList="nodownload"
				poster="/images/video-poster.png"
				onPlay={() => captureVideoActions('PLAY')}
				onPause={() => captureVideoActions('PAUSE')}
			>
				<source src="/videos/sensix-video.mp4" type="video/mp4" />
				<source src="/videos/sensix-video.webm" type="video/webm" />
				Sorry, your browser doesn't support embedded videos.
			</Video>
		</Container>
	);
}
