import React from 'react';
import styled from 'styled-components';

import { Colors } from 'environment';

interface Props {
	children: React.ReactNode;
	onClick?: () => void;
	onClose?: () => void;
}

export function DropdownInputItem({ children, onClick, onClose }: Props) {
	function handleClick() {
		onClick && onClick();
		onClose && onClose();
	}

	return <Container onClick={handleClick}>{children}</Container>;
}

const Container = styled.div`
	cursor: pointer;
	width: 100%;
	font-size: 1.8rem;
	font-family: 'Open Sans';
	padding: 1rem 1.6rem;
	color: ${Colors.indigo};
	border-bottom: 0.1rem solid ${Colors.gray[7]};
	border-left: 0.1rem solid ${Colors.gray[7]};
	border-right: 0.1rem solid ${Colors.gray[7]};
	transition: all 0.2s;

	:first-of-type {
		border-top: 0.1rem solid ${Colors.gray[7]};
	}

	:hover {
		color: ${Colors.green};
	}
`;
