import { graphql, useStaticQuery } from 'gatsby';

import { CustomMarkdownQueryData } from 'types';
import { markdownToHTMLParser } from 'helpers';

export function usePrivacyPolicyMarkdown() {
	const { allMarkdownRemark: data } = useStaticQuery<CustomMarkdownQueryData>(
		PRIVACY_POLICY_MARKDOWN
	);
	let binding: string[] = [];
	let nonBinding: string[] = [];

	data.edges.map(edge => {
		if (edge.node.frontmatter.category === 'binding') {
			binding = markdownToHTMLParser(edge.node.html);
		}

		if (edge.node.frontmatter.category === 'non-binding') {
			nonBinding = markdownToHTMLParser(edge.node.html);
		}
	});

	return { binding, nonBinding };
}

const PRIVACY_POLICY_MARKDOWN = graphql`
	query {
		allMarkdownRemark(filter: { frontmatter: { entity: { eq: "privacy" } } }) {
			edges {
				node {
					html
					frontmatter {
						category
					}
				}
			}
		}
	}
`;
