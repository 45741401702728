import styled from 'styled-components';
import { Colors } from 'environment';

export const Container = styled.button`
	border: 0;
	height: 6rem;
	width: 6rem;
	border-radius: 3rem;
	background-color: ${Colors.green};
	color: ${Colors.white};

	:disabled {
		opacity: 0.5;
	}

	:not(:disabled) {
		cursor: pointer;
	}
`;
