import Img from 'gatsby-image';
import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 12rem;

	@media ${MediaQueries.phone} {
		margin-bottom: 14rem;
	}

	@media ${MediaQueries.tablet} {
		margin-bottom: 30rem;
	}

	@media ${MediaQueries.desktop} {
		margin-bottom: 48rem;
	}
`;

export const Content = styled.div`
	width: 100%;

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const DiagramTitle = styled.h1`
	text-align: center;
	margin-bottom: 8rem;

	@media ${MediaQueries.phone} {
		font-size: 3.6rem;
		line-height: 5rem;
		font-family: 'Open Sans';
		font-weight: 600;
		padding: 0 2rem;
		margin-bottom: 4rem;
	}
`;

export const SolutionDiagramImage = styled.div`
	width: 100%;
`;

export const Image = styled(Img)`
	width: 100%;
	height: 100%;
	pointer-events: none;
	user-select: none;
`;
