import styled from 'styled-components';
import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
`;

interface DotProps {
	active: boolean;
}

export const Dot = styled.div<DotProps>`
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: ${({ active }) => (active ? Colors.green : Colors.gray[7])};
	margin-right: 1rem;
	cursor: pointer;
	pointer-events: all;
`;
