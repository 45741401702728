import styled, { css } from 'styled-components';
import { Colors } from 'environment';

interface Props {
	fullScreen?: boolean;
}

export const Container = styled.div<Props>`
	width: 100%;
	position: relative;
	cursor: grab;

	${({ fullScreen = false }) =>
		fullScreen &&
		css`
			& > div {
				position: fixed;
				top: 0;
				background-color: ${Colors.indigo};
				width: 100vw;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 100;
			}
		`}
`;

export const Icon = styled.img`
	cursor: pointer;
	position: absolute;
`;
