import React from 'react';

import { Container } from './Layout.style';
import 'environment/base.scss';
import 'prismjs/themes/prism.css';

interface Props {
	children: React.ReactNode;
}

/**
 * Because Layout is the first element under the QueryContext.Provider,
 * it should be memoized to stop propagating child updates when the
 * context value changes.
 */
export const Layout = React.memo(function ({ children }: Props) {
	return <Container>{children}</Container>;
});
