import { CSSProperties } from 'react';
import { useSpring as useBaseSpring, UseSpringProps } from 'react-spring';
import { Merge } from 'types';

export function useSpring<DS extends object>(values: UseSpringProps<Merge<DS, CSSProperties>>) {
	return useBaseSpring({
		config: {
			clamp: true,
			friction: 22
		},
		...values
	});
}
