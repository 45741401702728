import React, { useState, useRef } from 'react';

import { useOutsideClick } from 'hooks';

import { DropdownInputItem } from './DropdownInputItem';
import { Icon, Input, Menu } from './DropdownInput.style';
import { Container } from './style';

interface Props {
	selected: string;
	children: React.ReactElement | React.ReactElement[];
	label?: string;
	className?: string;
}

export function DropdownInput({ selected, children, label, className }: Props) {
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const menuRef = useRef<HTMLDivElement>(null);

	function handleClick() {
		setOpen(o => !o);
	}

	function handleClose() {
		setOpen(false);
	}

	function onOutsideClick(e: Event) {
		if (
			menuRef.current &&
			!menuRef.current.contains(e.target as Node) &&
			ref.current &&
			!ref.current.contains(e.target as Node)
		) {
			handleClose();
		}
	}

	useOutsideClick(onOutsideClick);

	const enhancedChildren = React.Children.map(children, child => {
		if (Array.isArray(child.props.children)) {
			return React.Children.map(child.props.children, c =>
				React.cloneElement(c, { onClose: handleClose })
			);
		}

		return React.cloneElement(child, { onClose: handleClose });
	});

	return (
		<Container className={className}>
			{label && <label>{label}</label>}
			<div ref={ref} onClick={handleClick}>
				<Input disabled value={selected} />
				<Icon open={open}>
					<img src="/images/arrow-down.svg" alt="Arrow down icon" />
				</Icon>
			</div>
			{open && <Menu ref={menuRef}>{enhancedChildren}</Menu>}
		</Container>
	);
}

DropdownInput.Item = DropdownInputItem;
