import { graphql, useStaticQuery } from 'gatsby';

import { CustomMarkdownQueryData } from 'types';

export function useCookiesPolicyMarkdown() {
	const { allMarkdownRemark: data } = useStaticQuery<CustomMarkdownQueryData>(
		COOKIES_POLICY_MARKDOWN
	);
	return data && data.edges ? data.edges[0].node.html : '';
}

const COOKIES_POLICY_MARKDOWN = graphql`
	query {
		allMarkdownRemark(filter: { frontmatter: { entity: { eq: "cookies" } } }) {
			edges {
				node {
					html
					frontmatter {
						category
					}
				}
			}
		}
	}
`;
