import { Link as BaseLink } from 'gatsby';
import { animated } from 'react-spring';
import styled from 'styled-components';

import { TABLET_GRID_SIZE, DESKTOP_GRID_SIZE } from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

const Wrapper = styled.header`
	position: fixed;
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: ${Colors.white};
	z-index: 10;
`;
export const AnimatedWrapper = animated(Wrapper);

export const Container = styled.div`
	display: flex;
	align-items: center;
	height: 12rem;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const ImageLink = styled(BaseLink)`
	height: 4rem;
`;

export const Image = styled.img`
	height: 4rem;
`;

export const Nav = styled.nav`
	display: flex;
	justify-content: flex-end;
	flex: 1;
`;

export const Link = styled(BaseLink)`
	position: relative;
	color: ${Colors.indigo};
	font-weight: 600;
	padding: 0 2rem;
	transition: color 0.2s;

	&.active-link {
		color: ${Colors.green};
	}

	&.active-link::after {
		content: '';
		height: 0.4rem;
		width: 100%;
		position: absolute;
		top: calc(7rem - 0.4rem);
		left: 0;
		background-color: ${Colors.green};
		border-radius: 0.2rem;
	}
`;
